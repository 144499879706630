<template>
  <v-container
    id="extended-tables-view"
    fluid
    tag="section"
  >
    <material-card
      icon="fas fa-users"
      icon-small
      color="primary"
      :title="$t('pageTitles.Accounts/AccountsList')"
    >
      <template #subtitle>
        <div class="text-right text-h3">
          <app-btn
            color="success"
            class="px-2 ml-1"
            elevation="0"
            min-width="0"
            small
            to="/accounts/new"
          >
            <v-icon
              small
              v-text="'fas fa-plus'"
            />
          </app-btn>
        </div>
      </template>

      <v-simple-table class="mt-4">
        <thead>
          <tr>
            <th>{{ $t('forms.last_name') }}</th>
            <th>{{ $t('forms.first_name') }}</th>
            <th>{{ $t('forms.email') }}</th>
            <th>{{ $t('forms.role') }}</th>
            <th class="text-right">
              {{ $t('forms.actions') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="!accounts || !accounts.length">
          <tr>
            <td colspan="3">
              <i
                v-if="processing"
                class="fas fa-spin fa-spinner"
              ></i>
              <template v-else>
                {{ $t('forms.nodata') }}
              </template>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr
            v-for="account in accounts"
            :key="`account-list-${account.id}`"
          >
            <td>{{ account.last_name }}</td>
            <td>{{ account.first_name }}</td>
            <td>{{ account.email }}</td>
            <td>{{ $t(`role_${account.role}`) }}</td>
            <td class="text-right table-actions">
              <v-btn
                color="success"
                class="px-2 ml-2"
                elevation="0"
                min-width="0"
                small
                :to="`/accounts/${account.id}`"
              >
                <v-icon
                  small
                  v-text="'far fa-edit'"
                />
              </v-btn>
              <app-btn
                color="error"
                class="px-2 ml-2"
                elevation="0"
                min-width="0"
                small
                @click="initiateAccountDelete(account)"
              >
                <v-icon
                  small
                  v-text="'fas fa-trash'"
                />
              </app-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </material-card>
    <v-dialog
      v-model="showDeletionDialog"
      width="300"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ $t('delete_confirm') }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="gray"
            text
            @click="closeDeletionDialog"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="deleteAccount"
          >
            {{ $t('delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import serverError from '../../mixins/serverError';
export default {
  name: 'AccountsList',

  mixins: [serverError],

  data: () => ({
    accounts: [],
    showDeletionDialog: false,
    accountToDelete: null,
  }),

  computed: {
    ...mapGetters({
      me: 'auth/getMe',
      users: 'account/getUsers',
      processing: 'global/getProcessing',
    }),
  },

  created() {
    this.$store.commit('account/setUser', null);
    this.refreshData();
  },

  methods: {
    refreshData() {
      this.accounts = [];
      this.$store.dispatch('account/fetchUsers')
        .then(() => {
          this.accounts = this.users.filter(user => user.role !== 'superadmin');
        });
    },
    initiateAccountDelete(account) {
      if (this.me.id === account.id) {
        this.$toasted.error(this.$t('cannot_delete_yourself'));
      } else {
        this.accountToDelete = account.id;
        this.showDeletionDialog = true;
      }
    },
    closeDeletionDialog() {
      this.accountToDelete = null;
      this.showDeletionDialog = false;
    },
    deleteAccount() {
      this.$store.dispatch('account/deleteUser', this.accountToDelete)
        .then(() => {
          this.$toasted.success(this.$t('item_deleted'));
          this.refreshData();
        })
        .catch(error => this.displayErrors(error))
        .finally(() => this.closeDeletionDialog());
    },
  },
}
</script>
